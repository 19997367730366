<template>
  <b-modal centered @hidden="hide" body-class="p-4" v-model="isShowModal">
    <template #modal-header>
      <div class="ft-black">Export log by Document</div>
    </template>
    <div>
      <multiselect
        close-on-select
        :clear-on-select="false"
        v-model="actionSelect"
        :options="optionList"
        placeholder="Select Status"
        track-by="id"
        label="name"
        :allow-empty="false"
        deselect-label=""
      ></multiselect>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button
            class="btn-confirm"
            @click.prevent="exportTemplate"
            :isLoading="isLoading"
          >
            Export
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: ["branchId"],
  data() {
    return {
      isShowModal: false,
      form: {
        branchId: null,
      },
      actionSelect: null,
      optionList: [],
      isLoading: false,
      filter: {
        branchId: null,
      },
    };
  },
  methods: {
    async show(data) {
      await this.getImportInventoryList();
      this.filter = {
        ...data,
      };
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },

    async getImportInventoryList() {
      const data = await this.axios(`/inventory/ImportInventoryList`, {
        params: {
          branchId: this.branchId,
        },
      });
      this.optionList = data.data.detail?.data || [];
      this.actionSelect = this.optionList[0];
    },

    async exportTemplate() {
      try {
        this.$bus.$emit("showLoading");

        let data = await this.axios(
          `/inventory/ExportImportInventory/${this.actionSelect.id}`,
          {
            responseType: "blob",
          }
        );
        if (data.status == 200) {
          this.hide();
          var fileURL = window.URL.createObjectURL(new Blob([data.data]));
          var fileLink = document.createElement("a");
          var dateExcel = this.$moment().format("DD-MM-YYYY");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Inventory-${this.actionSelect.name}-${dateExcel}.xlsx`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        } else {
          this.errorAlert(data.data.message);
        }
      } catch {
        this.errorAlert("Export Fails");
      } finally {
        this.$bus.$emit("hideLoading");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
</style>
