<template>
  <b-modal centered @hidden="hide" body-class="p-4" v-model="isShowModal">
    <template #modal-header>
      <div class="ft-black">{{ title }}</div>
    </template>
    <div>
      <b-row class="no-gutters mt-3">
        <b-col md="12" class="justify-content-start">
          <UploadFileV2
            textFloat="File"
            placeholder="Please Choose File"
            format="excel"
            name="file"
            text="*Please upload only file .xlsx less than 10 MB"
            isRequired
            v-on:onFileChange="onFileChange"
            v-on:delete="deleteFile"
            id="uploadfile"
            :fileName="filename"
            v-model="form.excel_file"
            :isValidate="$v.form.excel_file.$error"
            :v="$v.form.excel_file"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col md="6" class="justify-content-start">
          <InputText
            textFloat="Document No"
            placeholder="Document No"
            type="text"
            name="document_no"
            v-model="$v.form.document_no.$model"
            @onDataChange="
              (val) => {
                form.document_no = val;
              }
            "
            :v="$v.form.document_no"
            :isValidate="$v.form.document_no.$error"
            isRequired
          />
        </b-col>
        <b-col md="6" class="justify-content-start">
          <label class="label"
            >Document Date <span class="text-error">*</span>
          </label>
          <div
            :class="
              $v.form.document_time.$error
                ? 'border-error input-container'
                : 'input-container'
            "
          >
            <datetime
              type="datetime"
              :class="'date-picker mb-0'"
              :input-style="styleDatetime"
              v-model="form.document_time"
              placeholder="DD/MM/YYYY (HH:MM)"
              format="dd/MM/yyyy (HH:mm)"
              value-zone="Asia/Bangkok"
              ref="transferDateStart"
            >
            </datetime>
            <div
              :class="'icon-primary text-right'"
              @click="$refs.transferDateStart.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                :class="'pointer color-primary'"
                color="#B41BB4"
              />
            </div>
          </div>
          <div v-if="$v.form.document_time.$error">
            <span class="text-error">Please input start date.</span>
          </div>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button
            class="btn-confirm"
            @click.prevent="importTemplate"
            :isLoading="isLoading"
          >
            Upload
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    UploadFileV2,
    InputText,
  },
  props: [],
  data() {
    return {
      isShowModal: false,
      form: {
        branch_id: 0,
        document_no: "",
        document_time: "",
        inventory_activity_id: 1, //1 receive , 2 return
        excel_file: null,
      },
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoading: false,
      filename: "",
      isDisable: false,
      title: "",
    };
  },
  validations: {
    form: {
      excel_file: { required },
      document_no: { required },
      document_time: { required },
    },
  },
  methods: {
    deleteFile() {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    onFileChange(file) {
      this.filename = file.name;
      this.form.document_no = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    show(data) {
      this.resetForm();
      this.title =
        data.inventory_activity_id === 1
          ? "Receive Inventory"
          : "Return Inventory";
      this.form = {
        ...data,
      };
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    resetForm() {
      this.$v.form.$reset();
      this.form = {
        branch_id: 0,
        document_no: "",
        document_time: "",
        inventory_activity_id: 1, //1 receive , 2 return
        excel_file: null,
      };
      this.filename = null;
      this.isDisable = true;
    },
    downloadExcelFile(message, base64String) {
      const base64WithoutPrefix = base64String.replace(/^data:.+;base64,/, "");
      const binaryString = window.atob(base64WithoutPrefix);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileUrl = URL.createObjectURL(blob);
      const downloadLinkHTML = `<span>${message}</span>&nbsp&nbsp<a href="${fileUrl}" download="${this.filename}">Download</a>`;

      this.errorAlert(downloadLinkHTML);
    },

    async importTemplate() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      try {
        this.$bus.$emit("showLoading");
        const data = await this.axios.post(
          `/inventory/ImportInventoryPrice`,
          this.form
        );

        if (data.data.result === 1) {
          this.$emit("getData");
          this.successAlert();
        } else {
          if (data.data.detail) {
            this.downloadExcelFile(data.data.message, data.data.detail);
          } else {
            this.errorAlert(data.data.message);
          }
        }

        this.hide();
      } catch {
        this.errorAlert("Import Fails");
      } finally {
        this.$bus.$emit("hideLoading");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.border-error {
  border-color: red !important;
  border: 1px solid red;
  border-radius: 5px;
}
</style>
